<template>
  <!-- NAME[epic=销售] 批量到货 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <el-form-item label="" prop="">
        <el-select
          v-model="form.deliver_id"
          clearable
          style="width: 140px"
          placeholder="司机"
        >
          <el-option
            v-for="(i, idx) in driverSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.depot_id"
          clearable
          style="width: 140px"
          placeholder="出货仓库"
        >
          <el-option
            v-for="(i, idx) in depotSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.order_type"
          clearable
          style="width: 140px"
          placeholder="调拨状态"
        >
          <el-option
            v-for="(i, idx) in typeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="" prop="">
        <el-select
          v-model="form.account_type"
          clearable
          style="width: 140px"
          placeholder="结算方式"
        >
          <el-option
            v-for="(i, idx) in payTypeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.bill_type"
          clearable
          style="width: 140px"
          placeholder="来源"
        >
          <el-option
            v-for="(i, idx) in fromSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.time_type"
          clearable
          style="width: 140px"
          placeholder="时间类型"
        >
          <el-option
            v-for="(i, idx) in orderTimeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :w="250"
          @select-val-alldata="handleSelectCilent"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询已派订单</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      @selection-change="handleSelectRows"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">序号</template>
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.prop == 'bill_code'">
            <div class="underline" @click="handleCheck(1, row)">
              {{ row[item.prop] }}
            </div>
            <div
              v-if="row.allot_code"
              class="underline"
              @click="handleCheck(2, row)"
            >
              {{ row.allot_code }}
            </div>
          </div>
          <div v-else-if="item.prop == 'driver_money'">
            <!-- 司机收款 -->
            <div style="display: flex; justify-content: space-around">
              <el-input
                v-model="row.driver_money"
                style="width: 120px"
              ></el-input>
              <el-select
                v-model="row.pay_type"
                clearable
                style="width: 140px"
                placeholder="收款方式"
              >
                <el-option
                  v-for="(i, idx) in row.pay_list"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        fixed="right"
        min-width="100px"
      >
        <template #default="{ row }">
          <el-button
            v-if="row.allot_code == ''"
            type="text"
            @click="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button type="text" @click="handleArrival(row)">到货</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div
      style="display: flex; justify-content: space-around; align-items: center"
    >
      <div style="margin-top: 20px">
        <el-button type="primary" @click="hanldeBatchArrival">
          批量到货
        </el-button>
        <el-button type="warning" @click="hanldeBatchDb">批量调拨</el-button>
        <el-button type="danger" plain @click="handleBatchToZero">
          收款金额批量置零
        </el-button>
        <el-button @click="handleBatchToInput">批量填入待收款金额</el-button>
      </div>
      <el-pagination
        :current-page="form.pageNo"
        :layout="layout"
        :page-size="form.pageSize"
        :page-sizes="[10, 50, 100]"
        :total="total"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
    <order-edit ref="orderEdit" @refresh="fetchData"></order-edit>
    <check-xd ref="yudingdan"></check-xd>
    <check-db ref="diaobodan"></check-db>
  </div>
</template>
<script>
  import { getSelect } from '@/api/saleOrder'
  import { postAction } from '@/api/Employee'
  import clientSearch from '@/baseComponents/clientSearch'
  import orderEdit from '@/views/project/sale/bills/advanceOrder/components/orderTableEdit'
  import checkXd from '@/views/project/sale/bills/advanceOrder/components/checkOrder'
  import checkDb from '@/views/project/treasury/requisition/documents/components/AddGoods.vue'
  import store from '@/store'

  export default {
    name: 'BatchArrival',
    components: { clientSearch, orderEdit, checkXd, checkDb },
    data() {
      return {
        form: {
          pageNo: 1,
          pageSize: 10,
          time_type: 'create_at',
        },
        time: [],
        loading: false,
        // // 0未调拨 1已调拨
        // typeSelect: [
        //   {
        //     id: '0',
        //     name: '未调拨',
        //   },
        //   {
        //     id: '1',
        //     name: '已调拨',
        //   },
        // ],
        list: [],
        skTypeSelect: [],
        depotSelect: [],
        orderTimeSelect: [],
        driverSelect: [],
        typeSelect: [],
        fromSelect: [],
        payTypeSelect: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next',
        columns: [
          {
            label: '预订单 / 派单调拨',
            prop: 'bill_code',
            width: '170',
          },
          {
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            label: '来源',
            prop: 'bill_type_text',
            width: '',
          },
          {
            label: '出货仓库',
            prop: 'depot_name',
            width: '',
          },
          {
            label: '司机',
            prop: 'deliver_name',
            width: '',
          },
          {
            label: '订单金额',
            prop: 'total_amount',
            width: '',
          },
          {
            label: '待收款',
            prop: 'receiv_money',
            width: '',
          },
          {
            label: '结算方式',
            prop: 'account_type_text',
            width: '',
          },
          {
            label: '司机收款',
            prop: 'driver_money',
            width: '320',
          },
          {
            label: '下单时间',
            prop: 'create_at',
            width: '',
          },
        ],

        selectRows: [],
      }
    },
    computed: {
      refreshCount: () => {
        return store.state.socket.refreshCount
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      // 监听vuex 改变就刷新
      refreshCount(v) {
        if (v) {
          console.log('刷新')
          this.fetchData()
        }
      },
    },
    created() {
      this.initSelect()
    },
    mounted() {
      console.log('this.$store.store', this.$store.state.socket.refreshCount)

      this.fetchData()
    },
    methods: {
      initSelect() {
        getSelect().then((res) => {
          let data = res.data
          console.log(data)
          this.depotSelect = data.depot
          this.orderTimeSelect = data.time_type // 下单时间
          this.driverSelect = data.deliver //司机
          this.typeSelect = data.order_type //调拨状态
          this.fromSelect = data.bill_type //来源
          this.payTypeSelect = data.account_type //结算方式
        })
        // postAction('/promoteAdmin/deposit-order/pay-type').then((res) => {
        //   this.skTypeSelect = res.data
        // })
      },
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await postAction(
          '/saleAdmin/order-pre/batch-complete-list',
          this.form
        )
        this.total = totalCount
        this.list = data
        let d = this.list[0] && this.list[0].pay_list[0].id
        this.list.forEach((i) => {
          // i.pay_type = d
          this.$set(i, 'pay_type', d)
        })
        this.loading = false
      },
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
      handleSelectCilent(v) {
        console.log(v)
        this.form.cust_id = v.id
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleEdit(row) {
        console.log('row', row)
        this.$refs['orderEdit'].showEdit()
        this.$refs['orderEdit'].id = row.id
      },
      handleArrival(row) {
        this.arrival([row])
      },
      arrival(data, type = 1) {
        this.loading = true
        let url =
          type == 1
            ? '/saleAdmin/order-pre/batch-complete'
            : '/saleAdmin/order-pre/batch-allot'

        let d = JSON.parse(JSON.stringify(data))
        let diaobo = []

        d.forEach((i) => {
          i.amount = i.driver_money
          diaobo.push(i.id)
        })
        d = JSON.stringify(d)
        let endData =
          type == 1
            ? {
                order_data: d,
              }
            : {
                order_ids: diaobo.join(','),
              }
        postAction(url, endData)
          .then((r) => {
            // this.$message.info(r.msg)
            const h = this.$createElement
            this.$notify({
              title: type == 1 ? '到货结果' : '调拨结果',
              message: h('i', r.msg),
            })
            this.fetchData()
            this.loading = false
          })
          .catch((e) => {
            this.loading = false
          })
      },
      handleCheck(type, row) {
        if (type == 1) {
          // 预订单
          this.$refs['yudingdan'].orderStatus2 = row.bill_status_text
          this.$refs['yudingdan'].id = row.id
          this.$refs['yudingdan'].showDialog()
        } else {
          let r = JSON.parse(JSON.stringify(row))
          r.id = r.allot_id
          // 调拨单
          this.$refs.diaobodan.type = 1
          this.$refs.diaobodan.handlerlist(r)
          this.$refs.diaobodan.dialogVisible = true
        }
      },
      handleSelectRows(r) {
        this.selectRows = r
      },
      // 批量到货
      hanldeBatchArrival() {
        if (this.selectRows.length == 0) {
          this.$message.error('请先勾选数据')
        } else {
          this.$confirm(
            '请确定司机收款金额已填写正确，司机车辆库存充足。',
            '批量到货确认',
            { confirmButtonText: '确定', cancelButtonText: '取消' }
          )
            .then(() => {
              console.log('批量到货')
              this.arrival(this.selectRows)
            })
            .catch(() => {})
        }
      },
      // 批量调拨
      hanldeBatchDb() {
        if (this.selectRows.length == 0) {
          this.$message.error('请先勾选数据')
        } else {
          this.$confirm(
            '即将为未关联调拨单的订单做调拨，请确定出货仓库库存充足',
            '批量调拨确认',
            { confirmButtonText: '确定', cancelButtonText: '取消' }
          )
            .then(() => {
              console.log('批量调拨')
              this.arrival(this.selectRows, 2)
            })
            .catch(() => {})
        }
      },

      handleBatchToZero() {
        this.list.forEach((i) => {
          i.driver_money = 0
        })
      },
      handleBatchToInput() {
        this.list.forEach((i) => {
          i.driver_money = i.receiv_money
        })
        // this.$prompt('请输入应收金额', '批量填入应收金额', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   closeOnClickModal: false,
        // })
        //   .then(({ value }) => {
        //     if (Number(value) || value == 0) {
        //       this.list.forEach((i) => {
        //         i.driver_money = value
        //       })
        //     } else {
        //       this.$message.error('请正确输入金额')
        //     }
        //   })
        //   .catch(() => {})
      },
    },
  }
</script>
<style lang="scss" scoped></style>
